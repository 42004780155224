import React, { useState } from "react"
import { connect } from "react-redux"

import Markers from "../../../media/Images"

import imageMan from "../../../media/images/man.png"

import imageWoman from "../../../media/images/woman.png"

import narantiIcon from "../../../media/images/naranti-icon.png"

import logo from "../../../yt.jpg"

import {
    Button,
    Image,
    List,
    Responsive,
    Header,
    Embed
} from "semantic-ui-react"

import { Modal } from "react-bootstrap"

function HelpModal(props) {

    const [open, setOpen] = useState(false)
    const [buttonHidden, setHide] = useState(true)
    const [buttonIcon, setIcon] = useState('eye')
    const [ownMedia, setOwnMedia] = useState(true)
    const [showTutorial, setShowTutorial] = useState(true)

    const showOwnMedia = () => {
        if (props.media.markersList) {
            setOwnMedia(!ownMedia)
            props.media.markersList.forEach((marker) => {
                let show = false
                if(ownMedia) {
                    setIcon('eye slash')
                    if(marker.code)
                        if (String(marker.code.slice(0, 6)) === String('NAR401'))
                            show = true
                } else {
                    setIcon('eye')
                    show = true
                }
                marker.setVisible(show)
            })
            props.media.mapCircleList.forEach((circle) => {
                let visible = false
                props.media.markersList.forEach((marker) => {
                    if (
                        window.google.maps.geometry.spherical.computeDistanceBetween(
                            marker.getPosition(),
                            circle.getCenter()
                        ) <= circle.getRadius()
                    ) {
                        if (marker.getVisible()) visible = true
                    }
                })
                circle.setVisible(visible)
            })
            props.media.markerClusterer.repaint()
        }
    }

    return (
        <>
            <Responsive maxWidth={499}>
                <Button
                    className="text-help"
                    compact
                    size="tiny"
                    color="orange"
                    onClick={() => setOpen(true)}
                    icon="help"
                />
            </Responsive>
            <Responsive minWidth={500}>
                <Button
                    className="text-help"
                    compact
                    size="tiny"
                    color="orange"
                    onClick={() => setOpen(true)}
                    icon="help"
                    content="Ayuda"
                />
            </Responsive>
            
            <Modal
                show={open}
                // onShow={this.handleOpen}
                onHide={() => setOpen(false)}
                scrollable
            >
                <Modal.Header>
                    <Modal.Title onMouseEnter={() => {setHide(false)}} onMouseLeave={() => setHide(true)}>
                        Ayuda <Image src={narantiIcon} size="mini" color="orange" icon={buttonIcon} hidden={buttonHidden} onClick={() => showOwnMedia()}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button.Group>
                        <Button onClick={() => setShowTutorial(true)}>Tutorial</Button>
                        <Button onClick={() => setShowTutorial(false)}>Elementos</Button>
                    </Button.Group>
                    <Embed hidden={!showTutorial}
                        id='kfpdO6z6bBQ'
                        placeholder={logo}
                        source='youtube'
                    />
                    <List verticalAlign="middle" hidden={showTutorial}>
                        <List.Item>
                            <Image size="mini" src={Markers.azotea} />
                            <List.Content>
                                <List.Header as="h5">
                                    Unipolar, Azotea, Piso
                                </List.Header>
                                <List.Description>
                                    Ubicaciones de{" "}
                                    <b>Unipolares, Azoteas y Pisos</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.muro} />
                            <List.Content>
                                <List.Header as="h5">Muro</List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Muros</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.valla} />
                            <List.Content>
                                <List.Header as="h5">Valla</List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Vallas</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.pantalla} />
                            <List.Content>
                                <List.Header as="h5">Pantalla</List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Pantallas</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.landmark} />
                            <List.Content>
                                <List.Header as="h5">Landmark</List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Landmarks</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.parabus} />
                            <List.Content>
                                <List.Header as="h5">Parabus</List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Parabuses</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image
                                size="mini"
                                src={Markers["puente peatonal"]}
                            />
                            <List.Content>
                                <List.Header as="h5">
                                    Puente peatonal
                                </List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Puentes peatonales</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image
                                size="mini"
                                src={Markers["valla digital"]}
                            />
                            <List.Content>
                                <List.Header as="h5">
                                    Valla digital
                                </List.Header>
                                <List.Description>
                                    Ubicaciones de <b>Vallas digitales</b>.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        {/* <List.Item>
                            <Image
                                size="mini"
                                src={Markers["barda publicitaria"]}
                            />
                            <List.Content>
                                <List.Header as="h5">
                                    Barda publicitaria
                                </List.Header>
                                <List.Description>
                                    <p title="Representa las ubicaciones de varios Medios en un mismo sitio.">
                                        Ubicaciones de{" "}
                                        <b>Bardas publicitarias</b>.
                                    </p>
                                </List.Description>
                            </List.Content>
                        </List.Item> */}
                        <List.Item>
                            <Image size="mini" src={Markers.mediaGroup} />
                            <List.Content>
                                <List.Header as="h5">
                                    Agrupación de medios
                                </List.Header>
                                <List.Description>
                                    <Responsive maxWidth={499}>
                                        <p title="Representa las ubicaciones de varios Medios en un mismo sitio.">
                                            Representa la agrupación de{" "}
                                            <b>Medios</b>
                                        </p>
                                    </Responsive>
                                    <Responsive minWidth={500}>
                                        <p>
                                            Representa las ubicaciones de varios{" "}
                                            <b>Medios</b> en un mismo sitio.
                                        </p>
                                    </Responsive>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                    <Header as="h5" textAlign="center">
                        Comunicate con un asesor:
                    </Header>
                    <List
                        verticalAlign="top"
                        style={{ textAlign: "center" }}>
                        <List.Item>
                            <Image
                                avatar
                                src={
                                    props.media.currentExecutive.gender === "M"
                                        ? imageMan
                                        : imageWoman
                                }
                            />
                            <List.Content>
                                <List.Header
                                    href={`tel:+52${props.media.currentExecutive.phone}`}
                                    as="a">
                                    {/* {`${props.media.currentExecutive.name}`} */}
                                </List.Header>
                                <List.Description>
                                    <p>
                                        {/* {props.media.currentExecutive.phoneFormatted}
                                        <br/>
                                        Oficina: {props.media.currentExecutive.officePhoneNumberFormatted}<br/> */}
                                        <Button
                                            as='a'
                                            circular 
                                            color='green' 
                                            size='mini' 
                                            icon={{name: 'whatsapp', size:'big'}} 
                                            target='_blank'
                                            href={`
                                                https://wa.me/52${props.media.currentExecutive.officePhoneNumber}?text=%C2%A1Hola!+Vengo+del+Mapa+Naranti,+me+gustar%C3%ADa+obtener+m%C3%A1s+informaci%C3%B3n.&type=phone_number&app_absent=0
                                            `}
                                        />
                                    </p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="black"
                        onClick={() => setOpen(false)}
                        className="mr-auto"
                        content="Cerrar"
                    />
                    <Button
                        as="a"
                        color="green"
                        icon="phone"
                        labelPosition="right"
                        content="Llamar a un asesor"
                        href={`tel:+52${props.media.currentExecutive.phone}`}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(HelpModal)

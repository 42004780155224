export default {
    "Capital Federal": [],
    "Buenos Aires": [],
    "Catamarca": [],
    "Córdoba": [],
    "Corrientes": [],
    "Chaco": [],
    "Chubut": [],
    "Entre Ríos": [],
    "Formosa": [],
    "Jujuy": [],
    "La Pampa": [],
    "La Rioja": [],
    "Mendoza": [],
    "Misiones": [],
    "Neuquén": [],
    "Río Negro": [],
    "Salta": [],
    "San Juan": [],
    "San Luis": [],
    "Santa Cruz": [],
    "Santa Fe": [],
    "Santiago del Estero": [],
    "Tierra del Fuego": [],
    "Tucumán": []
}
import React, { Component } from "react"
import { connect } from "react-redux"
import { DEFAULT } from "../../../config"
import { GoogleApiWrapper } from "google-maps-react"
import statesMX from '../../../assets/mx/states'
import statesCO from '../../../assets/co/states'
import statesPA from '../../../assets/pa/states'
import statesPE from '../../../assets/pe/states'
import statesCL from '../../../assets/cl/states'
import statesGT from '../../../assets/gt/states'
import statesNI from '../../../assets/ni/states'
import statesCR from '../../../assets/cr/states'
import statesBO from '../../../assets/bo/states'
import statesHN from '../../../assets/hn/states'
import statesPY from '../../../assets/py/states'
import statesAR from '../../../assets/ar/states'
import statesEC from '../../../assets/ec/states'

import { toast } from "react-toastify"

import { Input, Dropdown } from "semantic-ui-react"

import { handleSetPointsOfInterest } from "../../../actions/mediaAction"

class SearchBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchBox: null,
        }
        this.onPlacesChanged = this.onPlacesChanged.bind(this)
        this.isEmptyOrSpaces = this.isEmptyOrSpaces.bind(this)
        this.getZoomByPlaceType = this.getZoomByPlaceType.bind(this)
        this.createMarkers = this.createMarkers.bind(this)
        this.onChangeSelect = this.onChangeSelect.bind(this)
        this.notifyError = this.notifyError.bind(this)
        this.notifySuccess = this.notifySuccess.bind(this)
        this.notifyInfo = this.notifyInfo.bind(this)
        this.makeID = this.makeID.bind(this)
        this.searchByMedium = this.searchByMedium.bind(this)
        this.updateStates = this.updateStates.bind(this)
        this.stateOptions = []
    }

    componentDidMount() {
        if (this.refs.searchInput) {
            var searchInput = this.refs.searchInput.inputRef.current
            const searchBox = new this.props.google.maps.places.SearchBox(
                searchInput
            )
            searchBox.addListener("places_changed", this.onPlacesChanged)
            this.setState({
                searchBox: searchBox,
            })
        }
        this.updateStates()
    }

    componentWillUnmount() {
        if (this.state.searchBox)
            this.state.searchBox.removeListener(
                "places_changed",
                this.onPlacesChanged
            )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.country !== this.props.country) {
            this.updateStates()
        }
    }

    updateStates = () => {
        if (this.props.states) {
            if (this.props.country == "MX") {
                this.stateOptions = []
                for (const state in statesMX) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " MX"
                        }
                    )
                }
            } else if (this.props.country == "CO") {
                this.stateOptions = []
                for (const state in statesCO) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " CO"
                        }
                    )
                }
            } else if (this.props.country == "PA") {
                this.stateOptions = []
                for (const state in statesPA) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " PA"
                        }
                    )
                }
            } else if (this.props.country == "PE") {
                this.stateOptions = []
                for (const state in statesPE) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " PE"
                        }
                    )
                }
            } else if (this.props.country == "CL") {
                this.stateOptions = []
                for (const state in statesCL) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " CL"
                        }
                    )
                }
            } else if (this.props.country == "GT") {
                this.stateOptions = []
                for (const state in statesGT) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " GT"
                        }
                    )
                }
            } else if (this.props.country == "NI") {
                this.stateOptions = []
                for (const state in statesNI) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " NI"
                        }
                    )
                }
            } else if (this.props.country == "CR") {
                this.stateOptions = []
                for (const state in statesCR) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " CR"
                        }
                    )
                }
            } else if (this.props.country == "BO") {
                this.stateOptions = []
                for (const state in statesBO) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " BO"
                        }
                    )
                }
            } else if (this.props.country == "HN") {
                this.stateOptions = []
                for (const state in statesHN) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " HN"
                        }
                    )
                }
            } else if (this.props.country == "PY") {
                this.stateOptions = []
                for (const state in statesPY) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " PY"
                        }
                    )
                }
            } else if (this.props.country == "AR") {
                this.stateOptions = []
                for (const state in statesAR) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " AR"
                        }
                    )
                }
            } else if (this.props.country == "EC") {
                this.stateOptions = []
                for (const state in statesEC) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " EC"
                        }
                    )
                }
            } else {
                this.stateOptions = []
                for (const state in statesMX) {
                    this.stateOptions.push(
                        {
                            key: state,
                            text: state,
                            value: state + " MX"
                        }
                    )
                }
            }
        }
    }

    notifyError = message =>
        toast.error(message, { position: toast.POSITION.BOTTOM_CENTER })

    notifyInfo = message =>
        toast.info(message, { position: toast.POSITION.BOTTOM_CENTER })

    notifySuccess = message =>
        toast.success(message, { position: toast.POSITION.BOTTOM_CENTER })

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null
    }

    searchByMedium(str) {
        return str.slice(0, 3).toUpperCase() == "NAR" && Number(str.slice(3, 4)) != "NaN" ? true : false
    }

    onPlacesChanged(searchSelectVal = null) {
        const searchValue = !searchSelectVal ? this.refs.searchInput.inputRef.current.value : searchSelectVal
        if (this.isEmptyOrSpaces(searchValue)) {
            this.notifyInfo(
                "Intenta nuevamente ingresando el nombre de algún punto de interes."
            )
            return false
        }
        const map = this.props.media.map

        if(this.searchByMedium(searchValue)) {
            const marker = this.props.media.markersList.filter(item => item.code === searchValue)[0]
            if (marker) {
                marker.setAnimation(this.props.google.maps.Animation.BOUNCE)
                setTimeout(() => {
                    marker.setAnimation(null)
                }, 4000)
                map.panTo(marker.position)
                map.setZoom(this.getZoomByPlaceType("medium"))
            } else {
                this.notifyError(
                    "Medio no encontrado, verifica que el código sea correcto."
                )
            }
        } else {
            const places = this.state.searchBox.getPlaces()
            if (!places) {
                this.notifyError(
                    "Busca primero tu ciudad y despues tus puntos de interes."
                )
                return false
            }
            if (places.length === 1) {
                const place = places[0]
                map.panTo(place.geometry.location)
                place.types.forEach((type) => {
                    map.setZoom(this.getZoomByPlaceType(type))
                })
            } else if (places.length > 1) {
                if (map.getZoom() >= 11) {
                    var request = {
                        location: map.getCenter(),
                        radius: "500",
                        query: this.refs.searchInput.inputRef.current.value,
                    }
    
                    let service = new window.google.maps.places.PlacesService(map)
                    service.textSearch(request, (places, status) => {
                        this.createMarkers(
                            places,
                            `${searchValue}_${this.makeID(5)}`
                        )
                        this.notifySuccess(
                            "Puntos de interes marcados en el mapa."
                        )
                    })
                } else {
                    this.notifyError(
                        "Busca primero tu ciudad y después tus puntos de interes."
                    )
                }
                // this.createMarkers(places, searchValue)
            }
        }
        this.refs.searchInput.inputRef.current.value = ""

    }

    makeID(length) {
        var result = ""
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            )
        }
        return result
    }

    createMarkers(places, index) {
        const map = this.props.media.map
        var bounds = new this.props.google.maps.LatLngBounds()
        // var placesList = document.getElementById('places')
        let markers = []
        places.forEach((place) => {
            var image = {
                url: place.icon,
                size: new this.props.google.maps.Size(71, 71),
                origin: new this.props.google.maps.Point(0, 0),
                anchor: new this.props.google.maps.Point(17, 34),
                scaledSize: new this.props.google.maps.Size(25, 25),
            }

            var marker = new this.props.google.maps.Marker({
                map: map,
                icon: image,
                title: place.name,
                position: place.geometry.location,
            })

            markers.push(marker)

            // var li = document.createElement('li')
            // li.textContent = place.name
            //   placesList.appendChild(li)

            bounds.extend(place.geometry.location)
        })
        this.props.dispatch(handleSetPointsOfInterest(markers, index))
        map.fitBounds(bounds)
    }

    getZoomByPlaceType(type) {
        switch (type) {
            case "continent":
                return 2
            case "country":
                return 5
            case "state":
                return 8
            case "locality":
                return 13
            case "postal_code" || "postal_code_prefix" || "postal_code_suffix":
                return 15
            case "sublocality":
                return 15
            case "point_of_interest" ||
                "establishment" ||
                "supermarket" ||
                "movie_theater" ||
                "store":
                return 16
            case "neighborhood":
                return 17
            case "medium":
                return 18
            default:
                return this.props.media.map.getZoom()
        }
    }

    onChangeSelect(e, data) {
        const map = this.props.media.map
        var request = {
            location: map.getCenter(),
            radius: '500',
            query: data.value
        }
        var service = new this.props.google.maps.places.PlacesService(map)
        service.textSearch(request, (e) => {
            const place = e[0]
            map.panTo(place.geometry.location)
            map.setZoom(this.getZoomByPlaceType("state"))
        })
    }


    render() {
        return (
            this.props.states != "true" ?
                <Input
                    ref="searchInput"
                    // action={{
                    //     style: Colors.styleSecondary,
                    //     icon: "search",
                    //     onClick: this.onPlacesChanged,
                    // }}
                    placeholder="Buscar..."
                    inverted={true}
                    size="small"
                    fluid={true}
                /> 
            :
                <Dropdown 
                    ref="searchSelect"
                    placeholder='Estados'
                    search 
                    selection 
                    fluid={true}
                    options={this.stateOptions} 
                    onChange={this.onChangeSelect}
                />
        )
    }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(
    GoogleApiWrapper({
        apiKey: DEFAULT.MAPS_API_KEY,
        libraries: ["places"],
    })(SearchBox)
)

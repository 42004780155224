import localForage from "localforage"

const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;

export const hasItemExpired = async (key) => {
    const now = (new Date()).getTime()
    const data = await localForage.getItem(key)
    const created_at = data ? data.created_at : now - 1000000000000000
    return Math.abs(now - created_at) > 10800000 ? true : false
}

export const saveToLocalStorage = (key, data) => {
    const now = (new Date()).getTime()
    localForage.setItem(key, {data: data, created_at: now})
}

export const getFromLocalStorage = async (key) => {
    return await localForage.getItem(key).then(data => data)
}

export const saveFavoritesOnLocal = (medium) => {
    const stored = JSON.parse(localStorage.getItem('favorites')) || [];
    if (stored.length == 0) {
        const now = (new Date()).getTime()
        localStorage.setItem('favoitesCreatedAt', now)
    }
    const mediums = [...stored,medium];
    localStorage.removeItem('favorites');
    localStorage.setItem('favorites', JSON.stringify(mediums));
}


export const deleteFavoritesOnLocal = (medium) => {
    const stored = JSON.parse(localStorage.getItem('favorites')) || [];
    const result = stored.filter((sto) => sto.id !== medium.id);
    localStorage.removeItem('favorites');
    localStorage.setItem('favorites', JSON.stringify(result));
}

export const getFavoritesFromLocal = () => {
    return JSON.parse(localStorage.getItem('favorites')) || false;
}

export const clearLocalStorage = () => {
    localStorage.clear();
}

export const timeOutToRefresh = () => {
    setTimeout(() => {
        clearLocalStorage()
        window.location.reload();
    }, threeHoursInMilliseconds);
}

export const checkExpiredTimeFavoriteList = () => {
    setInterval(() => {
        const createdAt = localStorage.getItem('favoitesCreatedAt');
        
        if (createdAt) {
            const now = Date.now();
            const elapsedTime = now - parseInt(createdAt, 10);

            if (elapsedTime > threeHoursInMilliseconds) {
                localStorage.removeItem('favorites');
                localStorage.removeItem('favoitesCreatedAt');
            }
        }
    }, 60000); 
};
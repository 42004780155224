import React, { Component } from "react"
import { connect } from "react-redux"

import Colors from "../../../media/Colors"

import imageMan from "../../../media/images/man.png"

import imageWoman from "../../../media/images/woman.png"

// import detectSwipe from "../../../utils/detectSwipe"


import {
    handleRemoveFavoriteMedium,
    handleShowMediaModal,
    handleSetCurrentMedium,
    handleRemovePointOfInterest,
    handleSetCountry
} from "../../../actions/mediaAction"

import "./Map.css"

import {
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Checkbox,
    Button,
    Label,
    Loader,
    Dimmer,
    Responsive,
    Card,
    Ref,
    Select
} from "semantic-ui-react"

import 'react-toastify/dist/ReactToastify.css'

import MapContainer from "./MapContainer"
import FavoriteModal from "./MediaModal"
import ContactModal from "./ContactModal"
import SearchBox from "./SearchBox"
import HelpModal from "./HelpModal"

import { country_settings } from "../../../utils/country_settings"
import ReactCountryFlag from "react-country-flag"
import { countries } from "../../../utils/countries"
import { getFavoritesFromLocal, timeOutToRefresh } from "../../../utils/common"

class Main extends Component {
    constructor(props) {
        super(props)
        //debemos dejar el estado false solo en movil
        this.state = {
            visible: (window.screen.availWidth < 500 ? false : true),
            typeFilter: {},
            selectAll: true,
            buttonSelectAll: "Deseleccionar todos",
            buttonGoToMap: "Ir al mapa",
            showSplashScreen: true,
            warningMessage: true,
            selectDigital: true,
            selectTraditional: true,
            country: props.match.params.country,
            settings: country_settings['MX']
        }

        this.showMenu = this.showMenu.bind(this)
        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this)
        this.getTypeFilter = this.getTypeFilter.bind(this)
        this.getFavorite = this.getFavorite.bind(this)
        this.getPointOfInterest = this.getPointOfInterest.bind(this)
        this.showFavotires = this.showFavotires.bind(this)
        this.selectAll = this.selectAll.bind(this)
        this.seletDigitalOrTraditional = this.seletDigitalOrTraditional.bind(this)
        this.hideMenu = this.hideMenu.bind(this)
        this.sideMenu = React.createRef()
        this.getCountryByLocation = this.getCountryByLocation.bind(this)
        this.setCountry = this.setCountry.bind(this)
        this.countryHasSettings = this.countryHasSettings.bind(this)
        this.updateMarkersAndCircles = this.updateMarkersAndCircles.bind(this)
        this.setFromStorage = this.setFromStorage(this)
    }

    setCountry(newCountry) {
        this.setState({
            country: newCountry,
            settings: country_settings[newCountry] ? country_settings[newCountry] : country_settings['MX']
        })
        this.props.dispatch(handleSetCountry(newCountry))
    }

    setFromStorage(){
        const stored = getFavoritesFromLocal();
        if(stored.length > 0){
            this.props.media.favoriteMediaList = stored;
        }
    }

    countryHasSettings(newCountry = null) {
        const currentCountry = newCountry ? newCountry : this.state.country
        return country_settings[currentCountry] ? true : false
    }

    componentDidMount() {
        timeOutToRefresh()

        if (this.state.country == undefined) {
            // this.setCountry('MX')
        }
        // detectSwipe('side_menu',this.hideMenu)
    }
    
    componentDidUpdate(previousProps, previousState) {
        if (previousState.country === undefined) {
            this.getCountryByLocation()
        }

    }
    

    getCountryByLocation () {
        const self = this
        
    }

    showMediaModal(medium) {
        this.props.dispatch(handleShowMediaModal(true))
        this.props.dispatch(handleSetCurrentMedium(medium))
    }

    showFavotires() {
        this.props.dispatch(handleShowMediaModal(true))
    }

    showMenu(e) {
        this.setState({
            visible: !this.state.visible,
        })
    }

    selectAll(e) {
        const checked = !this.state.selectAll;
        const buttonSelectAll = checked ? "Deseleccionar todos" : "Seleccionar todos";
        
        this.setState({ buttonSelectAll, selectAll: checked }, () => {
            const refs = Object.keys(this.refs);
            Promise.all(
                refs.map(ref => 
                    new Promise(resolve => 
                        this.refs[ref].setState({ checked }, resolve)
                    )
                )
            ).then(() => {
                this.updateMarkersAndCircles();
            });
        });
    }
    
    
    

    seletDigitalOrTraditional(e) {
        let digital = this.state.selectDigital
        let traditional = this.state.selectTraditional
        if (e == "traditional") {
            traditional = !traditional
            this.setState({
                selectTraditional: traditional
            })
            this.handleCheckboxFilter(e, {name: "traditional", checked: traditional})
        }
        if (e == "digital") {
            digital = !digital
            this.setState({
                selectDigital: digital
            })
            this.handleCheckboxFilter(e, {name: "digital", checked: digital})
        }
    }

    handleCheckboxFilter(e, { name, checked }) {
        
        if (!this.refs[name] && (name != 'traditional' && name != 'digital')) return;
        
        const refsToUpdate = [];
        
        if (name === "espectacular") {
            refsToUpdate.push("unipolar", "azotea", "piso");
        } else if (name === "digital") {
            refsToUpdate.push("pantalla", "pantalla electronica", "valla digital");
        } else if (name === "traditional") {
            const exclude = ["pantalla", "pantalla electronica", "valla digital"];
            Object.keys(this.refs).forEach(ref => {
                if (!exclude.includes(ref)) {
                    refsToUpdate.push(ref);
                }
            });
        }
        
        if (name != 'traditional' && name != 'digital')
            refsToUpdate.push(name);
        
        Promise.all(
            refsToUpdate.map(ref => 
                new Promise(resolve => 
                    this.refs[ref].setState({ checked }, resolve)
                )
            )
        ).then(() => {
            this.updateMarkersAndCircles();
        });
    }
    

    updateMarkersAndCircles() {
        if (this.props.media.markersList) {
            const checkedRefs = new Set(
                Object.keys(this.refs).filter(ref => this.refs[ref].state.checked)
            );
    
            this.props.media.markersList.forEach(marker => {
                const title = String(marker.getTitle());
                const show = checkedRefs.has(title);
                marker.setVisible(show);
            });
    
            this.props.media.mapCircleList.forEach(circle => {
                const visible = this.props.media.markersList.some(marker => {
                    return (
                        window.google.maps.geometry.spherical.computeDistanceBetween(
                            marker.getPosition(),
                            circle.getCenter()
                        ) <= circle.getRadius() && marker.getVisible()
                    );
                });
                circle.setVisible(visible);
            });
    
            this.props.media.markerClusterer.repaint();
        }
    }
    
    
    

    getTypeFilter(filter, index) {
        const maxLength = 8
        let id = filter.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        let name = `${filter.name.substr(0, maxLength)}`
        if (name == "Valla") name = "Valla fija"
        name += filter.name.length > maxLength ? "..." : ""
        let hidden = false
        
        if (
            id == "unipolar" || 
            id == "azotea" || 
            id == "piso" || 
            id == "vitral" || 
            id == "mampara" ||
            id == "cajero automatico" ||
            id == "pluma de estacionamiento" ||
            id == "pantalla electronica"
        ) hidden = true
        return (
            <Grid.Column 
                hidden={hidden}
                className="filter-container" 
                key={`filter_${index}`} 
                style={{paddingLeft: '0.5em', paddingRight: '0.5em'}}
            >
                {/* <Form.Group controlId={id} key={`filter_${index}`}>
          <Form.Check type="checkbox" label={name} onChange={ this.handleCheckboxFilter } name={id} ref={id} data-type="type" defaultChecked={true}/>
        </Form.Group> */}
                <div style={{
                    padding: '5px', 
                    background: Colors[id], 
                    display: 'inline-block', 
                    marginRight: '5px', 
                    borderRadius: '3px'
                }}>
                </div>
                <Checkbox
                    className="checkbox-white-label"
                    key={`filter_${index}`}
                    title={filter.name}
                    label={name}
                    fitted={true}
                    onChange={ this.handleCheckboxFilter }
                    name={id}
                    ref={id}
                    data-type="type"
                    defaultChecked={true}
                />
            </Grid.Column>
        )
    }

    removeFavorite(medium) {
        this.props.dispatch(handleRemoveFavoriteMedium(medium))
    }

    removePointOfInterest(index) {
        let points = this.props.media.pointsOfInterest[index]
        points.forEach((marker) => {
            marker.setMap(null)
        })
        this.props.dispatch(handleRemovePointOfInterest(index))
    }

    getFavorite(medium, index) {
        return (
            <Grid.Row key={`favorite_${index}`}>
                <Grid.Column
                    width={12}
                    textAlign="center"
                    verticalAlign="middle"
                >
                    <Label
                        style={Colors.styleSecondary}
                        as="a"
                        onClick={this.showMediaModal.bind(this, medium)}
                    >
                        {medium.full_code}
                    </Label>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    <Button
                        basic
                        compact
                        color="grey"
                        size="mini"
                        inverted
                        icon="trash"
                        title="Eliminar favorito"
                        onClick={this.removeFavorite.bind(this, medium)}
                    />
                </Grid.Column>
            </Grid.Row>
        )
    }

    getPointOfInterest(name, index) {
        const maxLength = 21
        let nameSub = `${name.substr(0, maxLength)}`
        nameSub += name.length > maxLength ? "..." : ""
        let button = (
            <Button
                basic
                compact
                color="grey"
                size="mini"
                icon="trash"
                title="Eliminar punto de interes"
                inverted
                onClick={this.removePointOfInterest.bind(this, name)}
            />
        )
        let width = 12
        if (name === "Sin puntos de interes") {
            button = <></>
            width = 16
        }
        return (
            <Grid.Row key={`favorite_${index}`}>
                <Grid.Column
                    width={width}
                    textAlign="center"
                    verticalAlign="middle"
                >
                    <Label style={Colors.styleSecondary}>{nameSub}</Label>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {button}
                </Grid.Column>
            </Grid.Row>
        )
    }

    hideMenu(el,d) {
        if (d == "l") {
            this.setState({visible: false})
        } else if (d == "u") {
            this.sideMenu.current.scrollIntoView(false)
        }
    }

    handleDismiss = () => {
        this.setState({ warningMessage: false })
    }

    render() {
        let renderFilters = () => {
            let types = this.props.media.filters.type

            if (types) {

                let filteredTypes = types.filter(type => {
                    return (type.name !== 'Medallon' || window.screen.availWidth > 500);
                });

                return filteredTypes.map((type, index) => {
                    return this.getTypeFilter(type, index)
                })
            }
        }

        let renderFavorites = () => {
            let favoriteMediaList = this.props.media.favoriteMediaList
            if (favoriteMediaList)
                return favoriteMediaList.map((medium, index) => {
                    return this.getFavorite(medium, index)
                })
        }

        let renderPointsOfInterest = () => {
            let pointsOfInterest = this.props.media.pointsOfInterest
            if (pointsOfInterest) {
                const points = []
                let index = 0
                for (const name in pointsOfInterest) {
                    points.push(this.getPointOfInterest(name, index))
                    index++
                }
                if (index === 0)
                    points.push(
                        this.getPointOfInterest("Sin puntos de interes", index)
                    )
                return points.map((point) => point)
            }
        }

        return (
            <div style={{ height: "100vh" }}>
                <Dimmer active={this.props.media.loading} page>
                    <Image src={this.state.settings.logo} size="medium" centered />
                    <h2>Empresa de medios exteriores con cobertura internacional </h2>
                    <Loader style={{marginTop: "200px"}} indeterminate>Cargando</Loader>
                </Dimmer>
                <Ref innerRef={this.sideMenu}>
                <Sidebar.Pushable as={Segment} id="side_menu" style={{display: 'flex'}}>
                    <Sidebar
                        as={Menu}
                        animation={ window.screen.availWidth > 500 ? "push" : "overlay"}
                        direction="left"
                        vertical
                        visible={this.state.visible}
                        style={{background: window.screen.availWidth > 500 ? Colors.primary : Colors.primaryOp}}
                        
                    >
                        <Menu.Item>
                            <Grid>
                                <Grid.Column width={16}>
                                    <Responsive maxWidth={499}>
                                        <Grid.Column  width={7} textAlign="right">
                                            <Button
                                                // basic
                                                textAlign="right"
                                                icon={{
                                                    name: this.state.visible ? "minus" : "bars",
                                                    size: "big",
                                                    style: Colors.styleSecondary
                                                }}
                                                style={{padding: "2px 0px 5px 0px", background: Colors.secondary }}
                                                onClick={this.showMenu}
                                            />
                                            <Label color="black" pointing='left'>¡Haz click para ocultar!</Label>
                                        </Grid.Column>
                                    </Responsive>
                                    <Image src={this.state.settings.logo} size="small" centered />

                                </Grid.Column>
                            </Grid>
                        </Menu.Item>
                        <Menu.Item>
                            <Grid columns={1}>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="filter" style={{color: Colors.secondary}}/>
                                        Tipo de medio
                                    </Header>
                                </Grid.Column>
                                <Grid.Column 
                                    className="filter-container" 
                                >
                                    <Checkbox
                                        className="checkbox-white-label"
                                        title="Tradicionales (OOH)"
                                        label="Tradicionales (OOH)"
                                        onChange={ () => this.seletDigitalOrTraditional("traditional") }
                                        checked={this.state.selectTraditional}
                                    />
                                    <Checkbox
                                        className="checkbox-white-label"
                                        title="Digitales (DOOH)"
                                        label="Digitales (DOOH)"
                                        onChange={ () => this.seletDigitalOrTraditional("digital") }
                                        checked={this.state.selectDigital}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Menu.Item>
                        <Menu.Item>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="search" style={{color: Colors.secondary}}/>
                                        Selecciona tu pais
                                    </Header>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Select
                                fluid={true} 
                                placeholder='Select your country' 
                                options={Object.entries(countries).map(([index, country]) => ({key: index.toLowerCase(), value: index.toLowerCase(), text: country}))}
                                defaultValue={this.state.country}
                                onChange={(e, data) => {
                                    window.location.href = `/${data.value.toLowerCase()}/ooh/`
                                }}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <Grid>
                                {/* <Grid.Column width={16}>
                                    {
                                        this.state.warningMessage ?
                                            <Message 
                                                warning
                                                onDismiss={this.handleDismiss}
                                                header='¡Busca primero tu ciudad y luego tus puntos de interés!'
                                            >
                                            </Message>
                                        : 
                                            <></>
                                    }
                                </Grid.Column> */}
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="search" style={{color: Colors.secondary}}/>
                                        Busca tu estado
                                    </Header>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <SearchBox states="true" country={this.state.country} />
                        </Menu.Item>
                        <Menu.Item>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="search" style={{color: Colors.secondary}}/>
                                        Buscar tu municipio, localidad
                                    </Header>
                                    <SearchBox />
                                </Grid.Column>
                            </Grid>
                        </Menu.Item>
                        <Menu.Item>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="search" style={{color: Colors.secondary}}/>
                                        Buscar puntos de interes
                                    </Header>
                                    <SearchBox />
                                    <Header as="h4" inverted>
                                        <Label color={Colors.secondary} size="tiny" basic horizontal>
                                            <Icon name="map marker alternate" />
                                            {Object.keys(this.props.media.pointsOfInterest).length}
                                        </Label>
                                        Puntos de interes
                                    </Header>
                                </Grid.Column>
                            </Grid>
                            <Grid centered className="favorites-section">
                                {renderPointsOfInterest()}
                            </Grid>
                        </Menu.Item>
                        <Menu.Item>
                            <Grid columns={2}>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="filter" style={{color: Colors.secondary}}/>
                                        Formato de medio
                                    </Header>
                                </Grid.Column>
                                {renderFilters()}
                                <Grid.Column width={16} textAlign="center">
                                    <Button
                                        style={Colors.styleSecondary}
                                        size="tiny"
                                        compact
                                        inverted
                                        content={this.state.buttonSelectAll}
                                        onClick={this.selectAll}
                                    />
                                </Grid.Column>
                                {
                                    window.screen.availWidth <= 500 && (
                                        <Grid.Column width={16} textAlign="center">
                                            <Button
                                                style={Colors.styleSecondary}
                                                size="tiny"
                                                compact
                                                inverted
                                                content={this.state.buttonGoToMap}
                                                onClick={(e) => this.setState({visible: false}) }
                                            />
                                        </Grid.Column>
                                    )
                                }
                            </Grid>
                        </Menu.Item>
                        {/* <Menu.Item>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Label color='orange' size="tiny" basic horizontal>
                                            <Icon name="shopping cart" />
                                            {this.props.media.favoriteMediaList.length}
                                        </Label>
                                        Carrito 
                                    </Header>
                                </Grid.Column>
                            </Grid>
                            <Grid centered className="favorites-section">
                                {renderFavorites()}
                            </Grid>
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        {this.props.media.favoriteMediaList.length ? (
                                            <Button
                                                color="green"
                                                size="small"
                                                compact
                                                onClick={this.showFavotires}
                                                content="Cotizar"
                                            />
                                        ) : (
                                            <Label style={Colors.styleSecondary}>
                                                Sin elementos
                                            </Label>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Menu.Item> */}
                        <Menu.Item>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h5" inverted>
                                        <Icon name="user" style={{color: Colors.secondary}}/>
                                        Contacto
                                    </Header>
                                </Grid.Column>
                            </Grid>
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        <Card>
                                            <Card.Content>
                                                <Card.Header>
                                                    <Image avatar src={ this.props.media.currentExecutive.gender == "M" ? imageMan : imageWoman } />
                                                    {this.props.media.currentExecutive.name}
                                                </Card.Header>
                                                <Card.Meta>{this.props.media.currentExecutive.position}</Card.Meta>
                                                <Card.Description>
                                                    <strong>
                                                        {this.state.country != 'MX' && this.state.country != '' ? '+52 ' : ''}
                                                        {this.props.media.currentExecutive.phoneFormatted}
                                                    </strong><br/><br/>
                                                    <strong>
                                                        Oficina: {this.state.country != 'MX' && this.state.country != '' ? '+52 ' : ''} 
                                                        {this.props.media.currentExecutive.officePhoneNumberFormatted}
                                                    </strong><br/><br/> 
                                                    <strong>
                                                        {this.props.media.currentExecutive.email}
                                                    </strong><br/><br/>
                                                    <Button 
                                                        as='a'
                                                        circular 
                                                        color='green' 
                                                        size='mini' 
                                                        icon={{name: 'whatsapp', size:'big'}} 
                                                        target='_blank'
                                                        href={`
                                                            https://wa.me/52${this.props.media.currentExecutive.officePhoneNumber}?text=%C2%A1Hola!+Vengo+del+Mapa+Naranti,+me+gustar%C3%ADa+obtener+m%C3%A1s+informaci%C3%B3n.&type=phone_number&app_absent=0
                                                        `}
                                                    />
                                                </Card.Description>
                                            </Card.Content>
                                            {
                                                (this.state.country == 'MX' || this.state.country == '' ) &&
                                                <Card.Content extra>
                                                    <div className="ui two buttons">
                                                    <Button 
                                                        as="a" 
                                                        href={`tel:+52${this.props.media.currentExecutive.phone}`} 
                                                        size="mini" 
                                                        compact 
                                                        // basic 
                                                        style={Colors.stylePrimary}
                                                        content="Llamar"
                                                    />
                                                    </div>
                                                </Card.Content>
                                            }
                                        </Card>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Menu.Item>
                    </Sidebar>
                    <Sidebar.Pusher style={{ paddingTop: "0px", background: Colors.secondary, display: 'flex', width: '100%' }}>
                        <Segment basic style={{display: 'flex', width: '100%', height: '100%', flexDirection: 'column'}}>
                            <Grid.Row style={{ paddingBottom: "0px", paddingLeft: "10px", paddingRight: '10px', display: 'flex' }}>
                                <div className="col-3">
                                    <Button
                                        // basic
                                        icon={{
                                            name: this.state.visible ? "minus" : "bars",
                                            size: "big",
                                            style: Colors.styleSecondary
                                        }}
                                        style={{padding: "2px 0px 5px 0px", background: Colors.secondary }}
                                        onClick={this.showMenu}
                                    />
                                    <Label hidden={!this.state.visible || window.screen.availWidth < 500} color="orange" pointing='left'>¡Haz click para ocultar!</Label>
                                </div>
                                <div className="col-5">
                                    <Responsive minWidth={768}>
                                        <Header as="h4" inverted >
                                                <Grid.Row className="d-flex justify-content-center align-item-center">
                                                    <Header style={{margin: 0}} as="h5" inverted>
                                                        Encuentra vallas publicitarias disponibles en:
                                                    </Header>
                                                </Grid.Row>
                                                <Grid.Row style={{display: 'flex', justifyContent: 'center'}}>
                                                    {
                                                        Object.keys(country_settings).map( key => (
                                                            <ReactCountryFlag
                                                                onClick={() => window.location.href = `/${key.toLowerCase()}/ooh/`}
                                                                countryCode={key} 
                                                                svg
                                                                title={countries[key]}
                                                                className="zoom-hover"
                                                                style={{
                                                                    paddingLeft: '5px', 
                                                                    paddingRight: '5px', 
                                                                    fontSize: '2.5em', 
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                </Grid.Row>
                                            
                                        </Header>
                                    </Responsive>
                                </div>
                                <div className="col-4" style={{display: 'flex'}}>
                                    <Responsive maxWidth={499} style={{paddingBottom: '1.5em'}}>
                                        <Button
                                            className="whatsapp-btn"
                                            hidden={this.state.visible}
                                            compact
                                            size="small"
                                            color="green"
                                            icon={{name: 'whatsapp'}} 
                                            target='_blank'
                                            href={`
                                                https://wa.me/52${this.props.media.currentExecutive.officePhoneNumber}?text=%C2%A1Hola!+Vengo+del+Mapa+Naranti,+me+gustar%C3%ADa+obtener+m%C3%A1s+informaci%C3%B3n.&type=phone_number&app_absent=0
                                            `}
                                        />
                                    </Responsive>
                                    <Responsive minWidth={500} style={{paddingBottom: '1.5em'}}>
                                        <Grid.Column width={1}>
                                            <Button
                                                className="whatsapp-btn"
                                                compact
                                                size="small"
                                                color="green"
                                                icon={{name: 'whatsapp'}} 
                                                labelPosition='right'
                                                content={`Whatsapp`}
                                                target='_blank'
                                                href={`
                                                    https://wa.me/52${this.props.media.currentExecutive.officePhoneNumber}?text=%C2%A1Hola!+Vengo+del+Mapa+Naranti,+me+gustar%C3%ADa+obtener+m%C3%A1s+informaci%C3%B3n.&type=phone_number&app_absent=0
                                                `}
                                            />
                                        </Grid.Column>
                                    </Responsive>
                                    <Responsive maxWidth={499} style={{paddingBottom: '1.5em'}}>
                                        <Button
                                            hidden={this.state.visible}
                                            className="shopping-cart"
                                            compact
                                            size="small"
                                            color="orange"
                                            onClick={this.showFavotires}
                                            icon="shopping cart"
                                            labelPosition='right'
                                            content={`${this.props.media.favoriteMediaList.length}`}
                                        />
                                    </Responsive>
                                    <Responsive minWidth={500} style={{paddingBottom: '1.5em'}}>
                                        <Grid.Column width={1}>
                                            <Button
                                                className="shopping-cart"
                                                compact
                                                size="small"
                                                color="orange"
                                                onClick={this.showFavotires}
                                                icon="shopping cart"
                                                labelPosition='right'
                                                content={`Ver carrito: ${this.props.media.favoriteMediaList.length}`}
                                            />
                                        </Grid.Column>
                                    </Responsive>
                                    <Responsive maxWidth={499} style={{paddingBottom: '1.5em'}}>
                                        {!this.state.visible ? <HelpModal /> : <></>}
                                    </Responsive>
                                    <Responsive minWidth={500} style={{paddingBottom: '1.5em'}}>
                                        <HelpModal />
                                    </Responsive>
                                </div>
                            </Grid.Row>
                            <Responsive maxWidth={499} 
                                style={{paddingBottom:'0.5em', paddingTop: '0.5em', width: '100%', display: 'flex', justifyContent: 'center'}}
                            >
                                <Grid.Row style={{display: `${!this.state.visible ? 'flex' : 'none'}`, justifyContent: 'center', width: '100%', flexDirection: 'column'}}>
                                    {/* new code  */}
                                    <Grid.Row style={{display: 'flex', justifyContent: 'center'}}>
                                        <Header style={{margin: 0}} as="h5" inverted>
                                            Localiza tu país
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{display: 'flex', justifyContent: 'center', marginLeft: '5px', marginRight: '5px',}}>
                                        <Select
                                            fluid={true} 
                                            placeholder='Select your country' 
                                            style={{
                                                textAlign: 'center' 
                                            }}
                                            options={
                                                Object.entries(countries).map(([index, country]) => ({
                                                    key: index.toLowerCase(), 
                                                    value: index.toLowerCase(), 
                                                    text: <>
                                                        <ReactCountryFlag
                                                            hidden={this.state.visible}
                                                            onClick={() => window.location.href = `/${index.toLowerCase()}/ooh/`}
                                                            countryCode={index} 
                                                            svg
                                                            title={country}
                                                            style={{
                                                                paddingLeft: '5px', paddingRight: '5px', fontSize: '2.5em', cursor: 'pointer'
                                                            }}
                                                        />
                                                        {country}
                                                    </>
                                                }))
                                            }
                                            defaultValue={
                                                this.state.country
                                            }
                                            onChange={(e, data) => {
                                                window.location.href = `/${data.value.toLowerCase()}/ooh/`
                                            }}
                                        />
                                    </Grid.Row>
                                    {/* <Grid.Row style={{display: 'flex', justifyContent: 'center'}}>
                                        <Header style={{margin: 0}} as="h5" inverted>
                                            Localiza tu país
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{display: 'flex', justifyContent: 'center'}}>
                                        {
                                            Object.keys(country_settings).map( key => (
                                                <ReactCountryFlag
                                                    hidden={this.state.visible}
                                                    onClick={() => window.location.href = `/${key.toLowerCase()}/ooh/`}
                                                    countryCode={key} 
                                                    svg
                                                    title={key}
                                                    style={{paddingLeft: '5px', paddingRight: '5px', fontSize: '2.5em', cursor: 'pointer' }}
                                                />
                                            ))
                                        }
                                    </Grid.Row> */}
                                </Grid.Row>
                            </Responsive>
                            <Grid.Row>
                                <Grid.Column width={16} style={{display: "flex"}}>
                                    <MapContainer countryHasSettings={this.countryHasSettings} setCountry={this.setCountry} country={this.state.country} />
                                    <FavoriteModal />
                                    <ContactModal />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid>
                            </Grid>
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                </Ref>
            </div>
        )
    }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(Main)